
import { defineComponent, ref } from 'vue';
import ExtendInformation from './ExtendInformation/index.vue';
import {
    docReview,
    docSampleDealer,
    extendTitleList,
    getTablelist,
    deleteTableItem,
} from '@/API/claim/delaerClaim/delaer';
import supportExample from '../UploadSupport/UploadExample.vue';
import downloadFile from '@/utils/claim/downloadFile';
import UploadSupport from './UploadSupport/index.vue';
import UploadInformation from './ExtendInformation/UploadInformation.vue';
import { b64toBlob } from '@/utils/claim/b64toBlob';
import { message, Modal } from 'ant-design-vue';
import { useRouter } from 'vue-router';
export default defineComponent({
    components: {
        ExtendInformation,
        supportExample,
        UploadSupport,
        UploadInformation,
    },
    emits: ['pre-page'],
    setup(props, ctx) {
        const selectTabs = ref(0);
        const router = useRouter();
        //
        const option = ref<any>({});

        //上传文档抽屉页面
        const uploadSupport = ref<any>(null);

        //批量上传扩展信息
        const uploadInformation = ref<any>(null);

        //预览抽屉
        const supportExample = ref<any>(null);

        //查看支持文档的抽屉
        const visible = ref(false);
        //预览文件
        const base64code = ref('');
        const type = ref('');
        const pdfHeight = window.innerHeight - 250;

        //子dom
        const extendInformationDom = ref<any>();
        //表格高度
        const tableHeight = window.innerHeight - 380;
        //表格宽度
        const tableWidth = ref(600);

        //样例的照片
        const exampleImg = ref('@/assets/images/rv/upload.svg');

        //是否只是单纯的预览上传的照片
        const isOnlyView = ref(false);

        //文档样例
        const supportSample = ref<any[]>([]);

        //如果没有扩展信息，不能点击上传扩展信息按钮
        const isSupport = ref(false);

        const dataSource = ref<any[]>([]);

        const columns = ref<any[]>([]);

        //没有扩展信息时，不能点击批量按钮
        const noDoc = ref(false);

        //关闭样例抽屉
        const closeDrawer = () => {
            visible.value = false;
        };

        //显示样例图片
        const showExample = (item: any) => {
            supportExample.value.showDrawer(item);
        };
        //编辑table一列
        const editTable = (item: any) => {
            const param = {
                groupId: option.value.programId,
                appId: item.id,
            };
            extendInformationDom.value.showDrawer(param, 'edit', option.value);
        };

        //添加新的扩展信息
        const addExtendInformation = () => {
            if (noDoc.value && dataSource.value.length > 0) {
                message.error('请在一条数据中，上传所有支持文档');
                return;
            }
            const param = {
                groupId: option.value.programId,
                incentiveCategory: option.value.incentiveCategory,
                offerTypeId: option.value.offerTypeId,
                offerTypeName: option.value.categoryName,
                programCode: option.value.programeCode,
                programName: option.value.programeName,
            };

            extendInformationDom.value.showDrawer(param, 'view', option.value);
        };

        const getTableData = () => {
            getTablelist({
                eligiblePeriodFrom: option.value.eligiblePeriodFrom,
                eligiblePeriodTo: option.value.eligiblePeriodTo,
                programId: option.value.programId,
                offerTypeId: option.value.offerTypeId,
                dealerCode: option.value.dealerCode,
            }).then((res: any): void => {
                // console.log('刷新表格')
                const p33 = res;

                dataSource.value = [];
                for (let i = 0; i < p33.length; i++) {
                    const one = {
                        id: p33[i].id,
                        supportDoc: p33[i].docList,
                    };
                    if (p33[i].extendInfoLst !== null) {
                        p33[i].extendInfoLst.forEach((item: any) => {
                            one[item.fieldNameCn] = item.content;
                        });
                    }

                    dataSource.value.push(one);
                }
            });
        };
        //删除table一列
        const deleteTable = (item: any) => {
            console.log(item);
            Modal.confirm({
                title: '请确认',
                content: '请确认是否要删除此条数据？',
                okText: '确认',
                onOk() {
                    deleteTableItem({ appId: item.id }).then(
                        (res: any): void => {
                            console.log(res);
                            if (res === '' || res === null) {
                                message.success('删除数据成功');
                                getTableData();
                            }
                        }
                    );
                },
                cancelText: '取消',
            });
        };

        //初始化view
        const init = (item: any, select: number) => {
            selectTabs.value = select;
            option.value = {
                progDesignId: item.programId,
                programId: item.programId,
                incentiveCategory: item.incentiveCategory,
                offerTypeId: item.offerTypeId,
                offerTypeName: item.categoryName,
                programCode: item.programeCode,
                programName: item.programeName,
                dealerNameCn: item.dealerNameCn,
                dealerCode: item.dealerCode,
                eligiblePeriodFrom: item.retailBegin,
                eligiblePeriodTo: item.retailEnd,
                offerTypeKey: item.offerTypeKey,
            };

            console.log(option.value);

            //进行初始化,进行异步访问

            const getExamplePic = docSampleDealer({
                programId: option.value.programId,
            }); //获取文档样例
            const getSupportArray = extendTitleList({
                programId: option.value.programId,
            }); //获取列表表头列信息
            const getTableData = getTablelist({
                eligiblePeriodFrom: option.value.eligiblePeriodFrom,
                eligiblePeriodTo: option.value.eligiblePeriodTo,
                programId: option.value.programId,
                offerTypeId: option.value.offerTypeId,
                dealerCode: option.value.dealerCode,
            }); //获取表格数据
            // cosnt p3 -

            Promise.all([getExamplePic, getSupportArray, getTableData])
                .then((result) => {
                    // console.log(result)               //['成功了', 'success']
                    if (result[0].length > 0) {
                        supportSample.value = result[0];
                    }

                    if (result[1].length === 0) {
                        noDoc.value = true;
                    }

                    //支持文档表格长度
                    const supportArrayWidth = ref(0);
                    //处理表格标题
                    const supportArray = result[1];
                    columns.value = [];
                    for (let i = 0; i < supportArray.length; i++) {
                        columns.value.push({
                            title: supportArray[i],
                            dataIndex: supportArray[i],
                            key: supportArray[i],
                            width: supportArray[i].length * 15,
                        });
                        supportArrayWidth.value =
                            supportArrayWidth.value +
                            supportArray[i].length * 8;
                    }
                    if (supportArrayWidth.value === 0) {
                        supportArrayWidth.value = 300;
                    }
                    columns.value.push({
                        title: '支持文档',
                        slots: { customRender: 'supportDoc' },
                        key: 'supportDoc',
                        width: supportArrayWidth.value,
                    });
                    columns.value.push({
                        title: '操作',
                        key: 'action',
                        slots: { customRender: 'action' },
                        width: 100,
                    });

                    if (supportArrayWidth.value + 100 > tableWidth.value) {
                        tableWidth.value = supportArrayWidth.value + 100;
                    }
                    // debugger

                    if (
                        result[2].length > 0 &&
                        result[2][0].docList.length === 0
                    ) {
                        isSupport.value = true;
                    }

                    //处理列表数据

                    const p33 = result[2];
                    for (let i = 0; i < p33.length; i++) {
                        const one = {
                            id: p33[i].id,
                            supportDoc: p33[i].docList,
                        };

                        if (p33[i].extendInfoLst === '') {
                            continue;
                        }
                        if (p33[i].extendInfoLst !== null) {
                            p33[i].extendInfoLst.forEach((item: any) => {
                                one[item.fieldNameCn] = item.content;
                            });
                        }
                        dataSource.value.push(one);
                    }

                    console.log(dataSource.value);
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        //预览支持文档
        const showSupport = (item: any, record: any) => {
            if (item.filePath != null && item.filePath.length > 0) {
                // supportExample.value.showDrawerReview(item);

                docReview({ collectionDocId: item.collectionDocId }).then(
                    (res: any): void => {
                        if (res.ext === 'jpg') {
                            base64code.value =
                                'data:image/jpg;base64,' + res.base64code;
                            type.value = 'jpg';
                        } else if (res.ext === 'pdf') {
                            base64code.value = URL.createObjectURL(
                                b64toBlob(res.base64code, 'application/pdf')
                            );
                            type.value = 'pdf';
                        }

                        visible.value = true;
                    }
                );
                // visible.value = true;
            }
        };

        //下载信息模板
        const dowloadDoc = () => {
            const downloadParams = {
                progDesignId: option.value.programId,
            };
            const params = {
                url: `/claimapi/dealer/doc/extendTemplateDownload`,
                method: 'get',
                params: downloadParams,
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8').then(
                (res) => {
                    console.log(res);
                }
            );
        };

        //批量上传文档
        const uploadSupportDraw = () => {
            uploadSupport.value.showDrawer(option.value);
        };

        //扩展信息批量上传
        const showUploadDrawer = () => {
            uploadInformation.value.showDrawer(option.value);
        };

        //进行跳转
        const nextStep = () => {
            console.log('nextStep');
            message.success('操作已完成');
            router.push({ name: 'Sales Incentive Claim' });
        };
        const preStep = () => {
            ctx.emit('pre-page', selectTabs.value);
        };

        // onMounted(() => {
        //   //根据传入的id和其他参数，获取列表信息
        //   init();

        //   option.value ={
        //     progDesignId:props.groupId,
        //     incentiveCategory: props.incentiveCategory,
        //     offerTypeId: props.categoryID,
        //     offerTypeName: props.categoryName,
        //     programCode:  props.programeCode,
        //     programName:  props.programeName,
        //     dealerNameCn: "",
        //     dealerCode: "",
        //   }

        // });
        return {
            preStep,
            isSupport,
            showUploadDrawer,
            uploadSupportDraw,
            uploadSupport,
            uploadInformation,
            dowloadDoc,
            base64code,
            type,
            pdfHeight,
            supportExample,
            supportSample,
            extendInformationDom,
            isOnlyView,
            visible,
            columns,
            exampleImg,
            dataSource,
            tableHeight,
            closeDrawer,
            editTable,
            deleteTable,
            addExtendInformation,
            showExample,
            init,
            showSupport,
            nextStep,
            getTableData,
            tableWidth,
            noDoc,
        };
    },
});
