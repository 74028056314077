
import { defineComponent, ref } from "vue";
import { docSampleReview ,docReview} from "@/API/claim/delaerClaim/delaer";
import { b64toBlob } from "@/utils/claim/b64toBlob";
export default defineComponent({
  setup() {
    //隐藏显示抽屉
    const visible = ref(false);

    //关闭抽屉
    const closeThisDrawer = () => {
      visible.value = false;
    };

    const title = ref("文档样例上传-居民身份证");
    //64码
    const base64code = ref("");
    //显示的类型
    const type = ref("");
    //概览的中文西悉尼
    const comment = ref("");
    //pdf高度
    const pdfHeight = window.innerHeight - 250;

    //显示抽屉方法
    const showDrawer = (item: any) => {
      docSampleReview({ docId: item.docId }).then((res: any): void => {
        if (res.ext === "jpg"){
          base64code.value = "data:image/jpg;base64," + res.base64code;
          type.value = "jpg";
        } else if (res.ext === "pdf"){
           base64code.value = URL.createObjectURL(
                  b64toBlob(res.base64code, "application/pdf")
                );
           type.value = "pdf";
        }
        
        title.value = "文档上传样例-" + item.docName;
        comment.value = res.comment;

        visible.value = true;
      });
    };

        //显示抽屉方法
    const showDrawerReview = (item: any) => {

      docReview({ collectionDocId: item.collectionDocId }).then((res: any): void => {
              debugger
        if (res.ext === "jpg"){
          base64code.value = "data:image/jpg;base64," + res.base64code;
          type.value = "jpg";
        } else if (res.ext === "pdf"){
           base64code.value = URL.createObjectURL(
                  b64toBlob(res.base64code, "application/pdf")
                );
           type.value = "pdf";
        }
        
        title.value = "文档上传样例-" + item.docName;
        comment.value = res.comment;

        visible.value = true;
      });
    };

    return {
      showDrawerReview,
      type,
      title,
      visible,
      pdfHeight,
      comment,
      base64code,
      showDrawer,
      closeThisDrawer,
    };
  },
});
