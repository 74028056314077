
import { defineComponent, ref, reactive, onMounted, nextTick } from 'vue';
import Submit from '../Submit/index.vue';
import DocumentSubmit from '../DocumentSubmit/index.vue';
import {
    getTabsData,
    saveClaimDealerProgramInfo,
    getTime,
} from '@/API/claim/delaerClaim/delaer';
import { useRoute, useRouter } from 'vue-router';
//申报类型
interface CategoryType {
    typeName: string;
    typeId: string;
    count: string;
    offerTypeNameEn: string;
    incentiveCategory: string;
    offerTypePathKey: string;
}
//tab页信息列表
interface TabsType {
    type: string;
    endTime: string;
    startTime: string;
    value: string;
    retailBegin: string;
    retailEnd: string;
    categoryArray: Array<CategoryType>;
    isDocument: boolean;
    eligiblePeriodId: string;
    eligiblePeriodName: string;
}

export default defineComponent({
    components: {
        Submit,
        DocumentSubmit,
    },
    beforeRouteEnter(to, from, next) {
        if (from.fullPath === '/claim/Dealer') {
            next();
        } else if (from.fullPath === '/claim/DealerVINList') {
            next();
        } else {
            next('/claim/Dealer');
        }
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const programId = ref(route.params.groupId);
        const programeCode = ref(route.params.programCode);
        const programeName = ref(route.params.programNameEn);
        const programNameCn = ref(route.params.programNameCn); //对应CN

        // const programBu =ref(route.params.programBu);
        const programBu = ref(route.params.programBu);
        const programBuName = ref(route.params.programBuName);
        const programBuSecond = ref(route.params.programBuSecond);
        const programBuSecondName = ref(route.params.programBuSecondName);

        const programOwner = ref(route.params.programOwner);
        const releaseDate = ref(route.params.releaseDate);

        const dealerCode = ref(route.params.dealerCode);
        // const dealerCode = ref('193120340')
        const dealerName = ref(route.params.dealerNameEN);
        const dealerNameCn = ref(route.params.dealerNameCN);
        // const offerTypeNameEn = ref('offerTypeNameEn');

        //概览内容
        const startTime = ref('startTime');
        const endTime = ref('endTime');
        const sumNum = ref(route.params.declaredVin);

        const retailBegin = ref('retailBegin');
        const retailEnd = ref('retailEnd');

        //子组件实例
        const SubmitDom = ref<any>(null);
        const DocumentSubmitDom = ref<any>(null);

        const isStop = ref(false);
        const isDocument = ref(false);

        //tabs相关内容
        const selectTabs = ref(0);
        const tabs = ref<TabsType[]>([
            {
                type: ' ',
                endTime: ' ',
                startTime: ' ',
                value: ' ',
                retailBegin: ' ',
                retailEnd: ' ',
                categoryArray: [],
                isDocument: false,
                eligiblePeriodId: '',
                eligiblePeriodName: '',
            },
        ]);

        const tabBarStyle = ref({
            background: 'white',
        });

        //申报类型单选框
        const offerTypeId = ref('');
        const categoryArray = ref<CategoryType[]>([]);
        const isCategory = ref(true);
        //单选框样式
        const radioStyle = reactive({
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            fontSize: '14px',
        });

        const option = reactive({
            offerTypeId: '',
            categoryName: '',
            programId: programId.value,
            programeName: programeName.value,
            programeCode: '',
            retailEnd: '',
            retailBegin: '',
            incentiveCategory: '',
            submissionDateFrom: '',
            submissionDateTo: '',
            offerTypeNameEn: '',
            dealerCode: dealerCode.value,
            dealerName: dealerName.value,
            programNameCn: programNameCn.value,
            dealerNameCn: dealerNameCn.value,
            offerTypePathKey: '',
            offerTypeKey: '',
            eligiblePeriodId: '',
            eligiblePeriodName: '',

            programBu: '',
            programBuName: '',
            programBuSecond: '',
            programBuSecondName: '',
            projectType: '',
        });
        const isShowPic = ref(true);

        //------所有事件--------
        const shouldShowStop = () => {
            getTime().then((res) => {
                if (Date.parse(endTime.value + ' 23:59:59') <= res) {
                    isStop.value = true;
                } else {
                    isStop.value = false;
                }
            });
        };

        //切换tabs
        const cutTabs = (activeKey: number) => {
            endTime.value = tabs.value[activeKey].endTime;
            startTime.value = tabs.value[activeKey].startTime;
            selectTabs.value = activeKey;
            categoryArray.value = tabs.value[activeKey].categoryArray;
            retailBegin.value = tabs.value[activeKey].retailBegin;
            retailEnd.value = tabs.value[activeKey].retailEnd;

            isDocument.value = tabs.value[activeKey].isDocument;
            isCategory.value = true;
            isShowPic.value = true;
            shouldShowStop();
        };

        const preStep = () => {
            router.push({
                name: 'Sales Incentive Claim',
                params: { isPre: 'true' },
            });
        };

        //进行下一步
        const nextStep = () => {
            // isCategory.value = false;

            option.programId = programId.value;

            let name = '';
            let incentiveCategory = '';
            let offerTypeNameEn = '';
            let offerTypeKey = '';
            for (
                let i = 0;
                i < tabs.value[selectTabs.value].categoryArray.length;
                i++
            ) {
                const ca = tabs.value[selectTabs.value].categoryArray[i];
                if (ca.typeId === offerTypeId.value.trim()) {
                    name = ca.typeName;
                    incentiveCategory = ca.incentiveCategory;
                    offerTypeNameEn = ca.offerTypeNameEn;
                    offerTypeKey = ca.offerTypePathKey;
                }
            }
            option.incentiveCategory = incentiveCategory;
            option.offerTypeId = offerTypeId.value;
            option.offerTypeNameEn = offerTypeNameEn;
            option.categoryName = name;
            option.programeName = programeName.value as string;
            option.programeCode = programeCode.value as string;
            option.retailBegin = retailBegin.value;
            option.retailEnd = retailEnd.value;
            option.submissionDateFrom = startTime.value;
            option.submissionDateTo = endTime.value;
            option.offerTypePathKey = offerTypeKey;
            option.offerTypeKey = offerTypeKey;
            option.eligiblePeriodId =
                tabs.value[selectTabs.value].eligiblePeriodId;
            option.eligiblePeriodName =
                tabs.value[selectTabs.value].eligiblePeriodName;
            option.programBu =
                programBu.value === 'null' ? '' : (programBu.value as string);
            option.programBuName =
                programBuName.value === 'null'
                    ? ''
                    : (programBuName.value as string);
            option.programBuSecond =
                programBuSecond.value === 'null'
                    ? ''
                    : (programBuSecond.value as string);
            option.programBuSecondName =
                programBuSecondName.value === 'null'
                    ? ''
                    : (programBuSecondName.value as string);
            option.projectType = tabs.value[selectTabs.value].type;
            //还未有数据的
            // option.programNameCn = "programNameCn";
            // option.dealerNameCn = "dealerNameCn";
            console.log(option);

            const params = {
                dealerCode: dealerCode.value,
                eligiblePeriodFrom: option.retailBegin,
                eligiblePeriodTo: option.retailEnd,
                offerTypeId: option.offerTypeId,
                offerTypeKey: option.offerTypeKey,
                offerTypeNameCn: option.categoryName,
                offerTypeNameEn: option.offerTypeNameEn,
                progDesignId: programId.value,
                programCode: programeCode.value,
                programNameCn: programNameCn.value,
                programNameEn: programeName.value,
                programOwner: programOwner.value,
                releaseDate: releaseDate.value,

                programBu: programBu.value === 'null' ? '' : programBu.value,
                programBuName:
                    programBuName.value === 'null' ? '' : programBuName.value,
                programBuSecond:
                    programBuSecond.value === 'null'
                        ? ''
                        : programBuSecond.value,
                programBuSecondName:
                    programBuSecondName.value === 'null'
                        ? ''
                        : programBuSecondName.value,
            };

            if (isDocument.value) {
                isCategory.value = false;
                nextTick(() => {
                    isShowPic.value = false;
                    DocumentSubmitDom.value.init(option, selectTabs.value);
                });
            } else {
                saveClaimDealerProgramInfo(params).then((res: any): void => {
                    isCategory.value = false;
                    nextTick(() => {
                        isShowPic.value = false;
                        SubmitDom.value.init(option, selectTabs.value);
                    });
                });
            }
        };

        //初始化页面
        const init = () => {
            getTabsData({
                progDesignId: programId.value as string,
                dealerCode: dealerCode.value as string,
            }).then((res: any): void => {
                tabs.value = [];
                //循环doc
                for (let i = 0; i < res.docPeriods.length; i++) {
                    const doc = res.docPeriods[i];
                    //radioCategoryArray
                    const supportArray = [];
                    const offertypeVos = doc.offertypeVos;

                    for (let i = 0; i < offertypeVos.length; i++) {
                        supportArray.push({
                            typeId: offertypeVos[i].progDesignOfferTypeId,
                            typeName: offertypeVos[i].progDesignOfferTypeNameCn,
                            count: offertypeVos[i].submissionCount,
                            offerTypeNameEn:
                                offertypeVos[i].progDesignOfferTypeName,
                            incentiveCategory:
                                offertypeVos[i].incentiveCategory,
                            offerTypePathKey:
                                offertypeVos[i].progDesignOfferTypePathKey,
                        });
                    }

                    const times =
                        doc.eligiblePeriodFrom + ' - ' + doc.eligiblePeriodTo;

                    tabs.value.push({
                        startTime: doc.submissionDateFrom,
                        endTime: doc.submissionDateTo,
                        type: '订单合同签署日期:',
                        value: times,
                        retailBegin: doc.eligiblePeriodFrom,
                        retailEnd: doc.eligiblePeriodTo,
                        categoryArray: supportArray,
                        isDocument: true,
                        eligiblePeriodId: doc.eligiblePeriodId,
                        eligiblePeriodName: doc.eligiblePeriodName,
                    });
                }

                //循环零售
                for (let i = 0; i < res.retailPeriods.length; i++) {
                    const doc = res.retailPeriods[i];
                    //radioCategoryArray
                    const supportArray = [];
                    const offertypeVos = doc.offertypeVos;

                    for (let i = 0; i < offertypeVos.length; i++) {
                        supportArray.push({
                            typeId: offertypeVos[i].progDesignOfferTypeId,
                            typeName: offertypeVos[i].progDesignOfferTypeNameCn,
                            count: offertypeVos[i].submissionCount,
                            offerTypeNameEn:
                                offertypeVos[i].progDesignOfferTypeName,
                            incentiveCategory:
                                offertypeVos[i].incentiveCategory,
                            offerTypePathKey:
                                offertypeVos[i].progDesignOfferTypePathKey,
                        });
                    }

                    const times =
                        doc.eligiblePeriodFrom + ' - ' + doc.eligiblePeriodTo;

                    tabs.value.push({
                        startTime: doc.submissionDateFrom,
                        endTime: doc.submissionDateTo,
                        type:
                            (res.retailPeriods[i].eligiblePeriodName as string)
                                .toLowerCase()
                                .indexOf('retail') > -1
                                ? '零售日期'
                                : '批售日期',
                        value: times,
                        retailBegin: doc.eligiblePeriodFrom,
                        retailEnd: doc.eligiblePeriodTo,
                        categoryArray: supportArray,
                        isDocument: false,
                        eligiblePeriodId: doc.eligiblePeriodId,
                        eligiblePeriodName: doc.eligiblePeriodName,
                    });
                }
                console.log(tabs.value);
                isDocument.value = tabs.value[selectTabs.value].isDocument;
                //对参数赋值
                retailBegin.value = tabs.value[selectTabs.value].retailBegin;
                retailEnd.value = tabs.value[selectTabs.value].retailEnd;
                endTime.value = tabs.value[selectTabs.value].endTime;
                startTime.value = tabs.value[selectTabs.value].startTime;
                //默认选取tabs
                categoryArray.value =
                    tabs.value[selectTabs.value].categoryArray;
                //默认第一个勾选
                offerTypeId.value = categoryArray.value[0].typeId;
                shouldShowStop();
            });
        };

        onMounted(() => {
            init();
        });

        return {
            selectTabs,
            tabBarStyle,
            tabs,
            startTime,
            endTime,
            sumNum,
            programeCode,
            programeName,
            retailBegin,
            retailEnd,
            offerTypeId,
            isCategory,
            categoryArray,
            radioStyle,
            isStop,
            SubmitDom,
            isDocument,
            nextStep,
            cutTabs,
            DocumentSubmitDom,
            isShowPic,
            programNameCn,
            preStep,
        };
    },
});
